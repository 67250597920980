@import "../../assets/css/colors.scss";
@import "../../assets/css/functions.scss";



.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}

.slider {
  background-color: $custom-006;

  .banner-text {
    padding: size(90) 0;
    text-align: left;
  }
}

.home-placeholder {
  padding-top: size(20);
  img {
    display: block;
    width: 50%;
    height: auto;
    margin: 0px auto;
  }
}